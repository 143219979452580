import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { CircularProgress, IconButton, Radio } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import S3Upload from './S3Upload';
import CachedIcon from '@mui/icons-material/Cached';

const theme = createTheme();

const Videos = () => {
  const [loading, setLoading] = useState(true);
  const [loadingYt, setLoadingYt] = useState(true);

  const [videos, setVideos] = useState([]);
  const [uploads, setUploads] = useState([]);

  const [selected, setSelected] = useState(null);

  const loadVideos = async () => {
    const { data } = await axios.post('https://epuf.mccd.dev/api', {
      action: 'LIST'
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const vds = [];
    for (const dt of data) {
      const url = await handleDownload(dt.key);
      vds.push({
        ...dt,
        downloadUrl: url
      });
    }

    setLoading(false);
    setVideos(vds);
  }

  const loadUploads = async () => {
    const { data } = await axios.post('https://epuf.mccd.dev/api', {
      action: 'LIST_UPLOADS'
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    setLoadingYt(false);
    setUploads(data);

    if (data.length > 0) {
      setSelected(data[0].key);
    }
  }

  const handleDownload = async (key) => {
    const { data } = await axios.post('https://epuf.mccd.dev/api', {
      action: 'DOWNLOAD',
      data: key
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return data;
  }

  const sendFile = async (title, selected) => {
    await axios.post('https://epuf.mccd.dev/api', {
      action: 'SEND_TO_YT',
      data: selected,
      title,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    await loadUploads();
  }

  useEffect(() => {
    loadVideos();
    loadUploads();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md" style={{ position: 'relative' }}>
        <CssBaseline />
        <div style={{ position: 'absolute', top: '-40px', right: 0 }}>
          <IconButton color="secondary" aria-label="refresh" onClick={() => {
            setVideos([]);
            setUploads([]);
            
            setLoading(true);
            setLoadingYt(true);

            loadVideos();
            loadUploads();
          }}>
            <CachedIcon />
          </IconButton>
        </div>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="h5" gutterBottom component="div" align='left'>
            Vidéos disponibles
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell>Nom</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="right">Durée</TableCell>
                  <TableCell align="right">Télécharger</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {videos.map((video) => (
                  <TableRow
                    key={video.key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {video.Metadata['x-mccd-title']}
                    </TableCell>
                    <TableCell align="left">{(new Date(video.Metadata['x-mccd-date'])).toLocaleString()}</TableCell>
                    <TableCell align="right">{parseInt(parseInt(video.Metadata['x-mccd-duration']) / 60000)} min</TableCell>
                    <TableCell align="right">
                      <IconButton color="secondary" aria-label="telecharger" onClick={(a, b, c) => {
                        window.open(video.downloadUrl, "_blank");
                      }}>
                        <DownloadIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          { loading && <div style={{ width: '100%', textAlign: 'center', padding: '1em' }}><CircularProgress /></div>}

        </Box>

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="h5" gutterBottom component="div" align='left'>
            Importer une nouvelle vidéo
          </Typography>
          <S3Upload />
        </Box>

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="h5" gutterBottom component="div" align='left'>
            Envoyer sur YouTube
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell align="left">Voir</TableCell>
                  <TableCell>Nom</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="right">YouTube</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uploads.map((video) => (
                  <TableRow
                    key={video.key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="right">
                      <Radio
                        checked={selected === video.key}
                        onChange={() => setSelected(video.key)}
                        value={video.key}
                        name="radio-buttons"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {video.title}
                    </TableCell>
                    <TableCell align="left">{(new Date(video.dt)).toLocaleString()}</TableCell>
                    <TableCell align="right">
                      { video.youtube && video.youtube.indexOf('http') === 0 && (
                        <a href={video.youtube} target="_blank">{video.youtube}</a>
                      )}

                      { video.youtube && video.youtube.indexOf('http') !== 0 && (
                        <span>{video.youtube}</span>
                      )}
                      
                      { !video.youtube && (
                        <IconButton color="secondary" aria-label="telecharger" onClick={(a, b, c) => {
                          sendFile(video.title, video.key);
                        }}>
                          <YouTubeIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          { loadingYt && <div style={{ width: '100%', textAlign: 'center', padding: '1em' }}><CircularProgress /></div>}

          { selected && uploads.find(v => v.key === selected) && (
            <>
              <div style={{ marginTop: '2em', width: '100%' }}>
                <Typography variant="h8" gutterBottom component="div" align='left'>
                  Prévisualiser
                </Typography>
                <video src={uploads.find(v => v.key === selected).downloadUrl} controls style={{ minWidth: '100%', maxWidth: '100%' }} />
              </div>
            </>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Videos;