import * as React from 'react';
import SignIn from './components/SignIn';
import Videos from './components/Videos';

const App = () => {
  const [step, setStep] = React.useState("LOGIN");

  return step === 'LOGIN' ? <SignIn setStep={setStep} /> : <Videos />;
}

export default App;