import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Evaporate from 'evaporate';
import AWS from 'aws-sdk';
import { Button, Chip, LinearProgress, TextField } from '@mui/material';
import axios from 'axios';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const S3Upload = () => {
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [error, setError] = useState(false);
  const [finished, setFinished] = useState(false);

  const [currentProgress, setCurrentProgress] = useState({
    progress: 0,
    readableSpeed: '?',
    secondsLeft: '?',
  });

  const sendFile = async () => {
    await uploadFile();

    await axios.post('https://epuf.mccd.dev/api', {
      action: 'REGISTER',
      title,
      filename: file.name,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    setFinished(true);
  }

  const uploadFile = async () => {
    setUploading(true);

    try {
      setCurrentProgress(0);
      await (new Promise((resolve, reject) => {
        const addConfig = {
          name: file.name,
          file,
          progress: (progressValue, stats) => {
            const percentCompleted = Math.round(progressValue * 100.0);
            setCurrentProgress({
              progress: percentCompleted,
              readableSpeed: stats.readableSpeed,
              secondsLeft: stats.secondsLeft,
            });
          },
          complete: async (_xhr, awsKey) => {
            resolve();
          },
          error: (err) => {
            console.log(err);
            reject();
          },
        };

        Evaporate.create({
          aws_key: 'AKIA2RROU5V72T6MSTJL',
          bucket: 'mccd-zoom-yt',
          awsRegion: 'eu-west-1',
          sendCanonicalRequestToSignerUrl: true,
          signerUrl: `https://epuf.mccd.dev/sign`,
          awsSignatureVersion: '4',
          computeContentMd5: true,
          logging: false,
          cryptoMd5Method(data) {
            return AWS.util.crypto.md5(data, 'base64');
          },
          cryptoHexEncodedHash256(data) {
            return AWS.util.crypto.sha256(data, 'hex');
          },
          signHeaders: {
            Authorization: `Bearer `,
          },
        }).then((evaporate) => evaporate.add(addConfig));
      }));
    } catch (e) {}
  };

  const fileInputRef = useRef(null);
  const inputFileChange = async (evt) => {
    if (fileInputRef.current.files.length === 0) {
      return;
    }

    const file = fileInputRef.current.files[0];
    setFile(file);
  };

  return (
    <Box
    component="form"
    sx={{
      '& .MuiTextField-root': { m: 1, width: '25ch' },
      width: '100%'
    }}
    noValidate
    autoComplete="off"
  >
      <TextField fullWidth error={error} onChange={(e, v) => {
        setTitle(e.target.value);
        setError(false);
      }} required size='small' id="outlined-basic" label="Titre de la vidéo" variant="outlined" />
      <div style={{
        margin: '8px'
      }}>
        <input
          ref={fileInputRef}
          type="file"
          onChange={inputFileChange}
          className="input-upload"
          disabled={uploading}
        />
        <LinearProgressWithLabel value={currentProgress.progress} />
      </div>
      <div style={{
        textAlign: 'center'
      }}>
        { !finished ? (
          <Button variant="contained" disabled={uploading||!file} onClick={() => {
            if (!title) {
              setError(true);
              return;
            }

            sendFile();
          }}>Envoyer</Button>
        ) : (
          <Chip label="Vidéo envoyée" color="success" />
        )}
      </div>
    </Box>
  );
};

export default S3Upload;
